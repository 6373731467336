import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useRouteLoaderData } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { useStoreSelector } from "../stores/store";
import { BookInfo, Chapter, Verse } from "../entities/translation.entity";
import VerseComponent from "../components/VerseComponent";
import Error from "../components/Error";
import { getChapter, getNextBook, getNextChapter, getPreviousBook, getPreviousChapter } from "../services/translation.service";
import styles from "./BookPage.module.css";
import Loader from "../components/Loader";

export default function BookPage() {
  const params = useParams();
  const navigate = useNavigate();
  const bookInfos = useRouteLoaderData("root") as BookInfo[];

  const isReaderMode = useStoreSelector((state) => state.readerMode.value);
  const isPrimitiveMode = useStoreSelector((state) => state.primitiveMode.value);
  const parallelTranslation = useStoreSelector((state) => state.translation.value);

  const [currentChapter, setCurrentChapter] = useState<Chapter>({ h: "", number: "", verses: [] });
  const [parallelChapter, setParallelChapter] = useState<Chapter>();
  const [currentChapterLoading, setCurrentChapterLoading] = useState(true);
  const [parallelChapterLoading, setParallelChapterLoading] = useState(false);

  const [touchStart, setTouchStart] = useState<number>(0);
  const [touchEnd, setTouchEnd] = useState<number>(0);
  const minSwipeDistance = 120;

  useEffect(() => {
    const fetchCurrentChapter = async () => {
      if (params.book !== undefined && params.chapter !== undefined) {
        const bookName = params.book?.replaceAll(" ", "").toLowerCase();
        const bookInfo = bookInfos.find(bookInfo => bookInfo.h.replaceAll(" ", "").toLowerCase() === bookName);
        setCurrentChapterLoading(true);
        if (bookInfo !== undefined) {
          try {
            const chapter = await getChapter(bookInfo.code, params.chapter, undefined, isPrimitiveMode + "");
            setCurrentChapter(chapter);
          } catch (e) {
            // Bei einem Fehler
          }
        }
        setCurrentChapterLoading(false);
      }
    };
    fetchCurrentChapter();
  }, [params, isPrimitiveMode]);

  useEffect(() => {
    const fetchParallelChapter = async () => {
      if (params.book !== undefined && params.chapter !== undefined) {
        const bookName = params.book?.replaceAll(" ", "").toLowerCase();
        const bookInfo = bookInfos.find(bookInfo => bookInfo.h.replaceAll(" ", "").toLowerCase() === bookName);
        setParallelChapterLoading(true);
        if (bookInfo !== undefined) {
          try {
            const chapter = await getChapter(bookInfo.code, params.chapter, parallelTranslation);
            setParallelChapter(chapter);
          } catch (e) {
            // Bei einem Fehler
          }
        }
        setParallelChapterLoading(false);
      }
    };

    if (parallelTranslation !== "") {
      fetchParallelChapter();
    } else {
      setParallelChapter(undefined);
    }
  }, [params, parallelTranslation]);

  if (currentChapterLoading || parallelChapterLoading) {
    return <Loader />;
  }

  if (currentChapter.verses.length === 0) {
    return <Error />;
  }

  return (
    <>
      <div>
        <div style={{backgroundColor: isReaderMode ? "#fafafa" : "white"}} className={styles.mainContainer}
          onTouchStart={e => {
            setTouchEnd(e.targetTouches[0].clientX);
            setTouchStart(e.targetTouches[0].clientX);
          }}
          onTouchMove={e => setTouchEnd(e.targetTouches[0].clientX)}
          onTouchEnd={() => {
            const distance = touchStart - touchEnd;
            if (distance !== 0) {
              const isLeftSwipe = distance > minSwipeDistance;
              const isRightSwipe = distance < -minSwipeDistance;
              if (isLeftSwipe) {
                const fetchNextChapter = async () => {
                  try {
                    const bookChapterNavigation = await getNextChapter(currentChapter.h, currentChapter.number);
                    if (bookChapterNavigation !== undefined) {
                      navigate("/" + bookChapterNavigation.h + "/" + bookChapterNavigation.chapterNumber);
                    }
                  } catch (e) {
                    // Bei einem Fehler
                  }
                };
                fetchNextChapter();
                return;
              }
              if (isRightSwipe) {
                const fetchPreviousChapter = async () => {
                  try {
                    const bookChapterNavigation = await getPreviousChapter(currentChapter.h, currentChapter.number);
                    if (bookChapterNavigation !== undefined) {
                      navigate("/" + bookChapterNavigation.h + "/" + bookChapterNavigation.chapterNumber);
                    }
                  } catch (e) {
                    // Bei einem Fehler
                  }
                };
                fetchPreviousChapter();
                return;
              }
            }
          }}
        >
          <h1 className={styles.header}>{currentChapter.h}</h1>
          <h2 className={styles.chapter}>Chapter {currentChapter.number}</h2>
          <div className={styles.pageContainer}>
            <button style={{backgroundColor: isReaderMode ? "#fafafa" : "white"}} className={styles.changePageButton + " material-symbols-outlined"}  onClick={() => {
              const fetchPreviousBook = async () => {
                try {
                  const bookChapterNavigation = await getPreviousBook(currentChapter.h);
                  if (bookChapterNavigation !== undefined) {
                    navigate("/" + bookChapterNavigation.h + "/1");
                  }
                } catch (e) {
                  // Bei einem Fehler
                }
              };
              fetchPreviousBook();
            }}>
              keyboard_double_arrow_left
            </button>
            <button style={{backgroundColor: isReaderMode ? "#fafafa" : "white"}} className={styles.changePageButton + " material-symbols-outlined"} onClick={() => {
              const fetchPreviousChapter = async () => {
                try {
                  const bookChapterNavigation = await getPreviousChapter(currentChapter.h, currentChapter.number);
                  if (bookChapterNavigation !== undefined) {
                    navigate("/" + bookChapterNavigation.h + "/" + bookChapterNavigation.chapterNumber);
                  }
                } catch (e) {
                  // Bei einem Fehler
                }
              };
              fetchPreviousChapter();
            }}>
              keyboard_arrow_left
            </button>
            <div className={styles.paragraphContainer}>
              {parallelChapter !== undefined 
              ? <div>
                  <div className={styles.parallelHeaderContainer}>
                    <h2 className={styles.parallelItem + " " + styles.header}>AICNT</h2>
                    <h2 className={styles.parallelItem + " " + styles.header}>{parallelTranslation}</h2>
                  </div>
                  {Array.from({ length: Math.max(Array.from(groupBy(currentChapter.verses).keys()).length, (Array.from(groupBy(parallelChapter.verses).keys()).length || 0)) }).map((_, i) => (
                    <div key={i} className={styles.parallelContainer}>
                      <div className={styles.parallelItem}>
                        {groupBy(currentChapter.verses).get(i + 1)?.map((verse, j) => <VerseComponent key={j} verse={verse} isParallel={true} />)}
                      </div>
                      <div className={styles.parallelItem}>
                        {groupBy(parallelChapter.verses).get(i + 1)?.map((verse, j) => <VerseComponent key={j} verse={verse} isParallel={true} />)}
                      </div>
                    </div>
                  ))}
                </div>
              : currentChapter.verses.map((verse, i) => (<VerseComponent key={i} verse={verse} isParallel={false} />))}
            </div>
            <button style={{backgroundColor: isReaderMode ? "#fafafa" : "white"}} className={styles.changePageButton + " material-symbols-outlined"} onClick={() => {
              const fetchNextChapter = async () => {
                try {
                  const bookChapterNavigation = await getNextChapter(currentChapter.h, currentChapter.number);
                  if (bookChapterNavigation !== undefined) {
                    navigate("/" + bookChapterNavigation.h + "/" + bookChapterNavigation.chapterNumber);
                  }
                } catch (e) {
                  // Bei einem Fehler
                }
              };
              fetchNextChapter();
            }}>
              keyboard_arrow_right
            </button>
            <button style={{backgroundColor: isReaderMode ? "#fafafa" : "white"}} className={styles.changePageButton + " material-symbols-outlined"} onClick={() => {
              const fetchNextBook = async () => {
                try {
                  const bookChapterNavigation = await getNextBook(currentChapter.h);
                  if (bookChapterNavigation !== undefined) {
                    navigate("/" + bookChapterNavigation.h + "/1");
                  }
                } catch (e) {
                  // Bei einem Fehler
                }
              };
              fetchNextBook();
            }}>
              keyboard_double_arrow_right
            </button>
          </div>
          {parallelChapter === undefined
          ? !isReaderMode &&
            <div className={styles.footnoteContainer}>
              {currentChapter.verses.map(verse => verse.texts.map(text => text.footnotes.map((footnote, i) => (
                <p key={i}>
                  <b>{footnote.index + ": "}</b>
                  <span>{footnote.fr + " "}</span>
                  <span><b>{footnote.fk + " "}</b></span>
                  {footnote.ft.map((footnoteText, j) => (
                    <span key={j}>
                      {footnoteText.italic ? <i>{footnoteText.text}</i> : footnoteText.superscript ? <sup>{footnoteText.text}</sup> : footnoteText.text}
                    </span>
                  ))}
                </p>
              ))))}
            </div>
          : <div className={styles.translationContainer}>
              <div dangerouslySetInnerHTML={{__html: parallelChapter.copyright || ""}}></div>
              <div dangerouslySetInnerHTML={{__html: parallelChapter.info || ""}}></div>
            </div>
          }
        </div>
      </div>

      <Tooltip className={styles.tooltip} id="footnote-tooltip" disableStyleInjection={true} clickable />
    </>
  );
}

function groupBy(verses: Verse[]): Map<number, Verse[]> {
  const versesMap = new Map<number, Verse[]>();
  for (const verse of verses) {
      const number = +verse.number;
      let verseList = versesMap.get(number);
      if (verseList === undefined) {
        verseList = [];
        versesMap.set(number, verseList);
      }
      verseList.push(verse);
  }
  return versesMap;
};
