import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactDOMServer from 'react-dom/server';
import { FormControl, FormControlLabel, FormGroup, FormHelperText, MenuItem, Select, Switch } from "@mui/material";
import { Tooltip } from "react-tooltip";
import BookChapterForm from "./BookChapterForm";
import { useStoreDispatch, useStoreSelector } from "../stores/store";
import { setReaderMode } from "../stores/readerModeSlice";
import { setPrimitiveMode } from "../stores/primitiveModeSlice";
import { setFont } from "../stores/fontSlice";
import { setTranslation } from "../stores/translationSlice";
import { getTranslations } from "../services/translation.service";
import styles from "./Header.module.css";

interface Properties {
  className: string;
}

export default function Header(props: Properties) {
  const navigate = useNavigate();

  const dispatch = useStoreDispatch();
  const isReaderMode = useStoreSelector((state) => state.readerMode.value);
  const isPrimitiveMode = useStoreSelector((state) => state.primitiveMode.value);
  const currentFont = useStoreSelector((state) => state.font.value);
  const currentTranslation = useStoreSelector((state) => state.translation.value);

  const [translations, setTranslations] = useState<string[]>([]);

  const fonts = ["Gentium Plus", "Cardo", "Nuosu SIL", "Lato", "PT Sans", "Alegreya Sans", "Noto Sans JP", "Nunito"];

  useEffect(() => {
    const fetchTranslations = async () => {
      try {
        const translations = await getTranslations();
        setTranslations(translations);
      } catch (e) {
        // Bei einem Fehler
      }
    };
    fetchTranslations();
  }, []);

  return (
    <div className={props.className + " " + styles.mainContainer}>
      <div className={styles.adContainer}>
        <a href="https://amzn.to/3slC6Gy" className={styles.adButton}>
          <span className={styles.adSpan}><i>GPT.</i>Bible publications on Amazon</span>
          <span className={styles.adButtonInfo}>Commission earned from qualifying purchases</span>
        </a>
        <a href="https://dashboard.mailerlite.com/forms/553339/96550735583380682/share" className={styles.adButton}>
          <span className={styles.adSpan}>Sign up for updates</span>
          <span className={styles.adButtonInfo}>Integrity Syndicate newsletter</span>
        </a>
        <a href="https://www.paypal.com/donate/?hosted_button_id=TLXXS6CNYWQZ4&sdkMeta=eyJ1cmwiOiJodHRwczovL3d3dy5wYXlwYWxvYmplY3RzLmNvbS9kb25hdGUvc2RrL2RvbmF0ZS1zZGsuanMiLCJhdHRycyI6eyJkYXRhLXVpZCI6ImFkMGJmMDdiYzRfbWRjNm50ZzZtZGkifX0&targetMeta=eyJ6b2lkVmVyc2lvbiI6IjlfMF81OCIsInRhcmdldCI6IkRPTkFURSIsInNka1ZlcnNpb24iOiIwLjguMCJ9"
          className={styles.adButton}
        >
          <span className={styles.adSpan}>Contribute Now</span>
          <span className={styles.adButtonInfo}>Help fund this critical work</span>
        </a>
      </div>
      <div className={styles.controlContainer}>
        <div className={styles.logo} onClick={() => navigate("")}>
          <span className={styles.mainLogo}><i><b>GPT.</b></i>BIBLE</span>
          <span className={styles.subLogo}>AI Critical New Testament (AICNT)</span>
        </div>
        <div>
          <BookChapterForm showChapter={true} />
        </div>
        <ul className={styles.menu}>
          <li>
            <FormGroup>
              <FormControlLabel control={
                <Switch
                  checked={isReaderMode}
                  onChange={() => dispatch(setReaderMode(!isReaderMode))}
                />
              } label="Readers Mode" data-tooltip-id="mode-tooltip" data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                <div className={styles.tooltipContainer}>Readers Mode: Hides verse numbers and footnotes. Darkens the background.</div>
              )} />
            </FormGroup>
          </li>
          <li>
            <FormGroup>
              <FormControlLabel control={
                <Switch
                  checked={isPrimitiveMode}
                  onChange={() => dispatch(setPrimitiveMode(!isPrimitiveMode))}
                />
              } label="Primitive Mode" data-tooltip-id="mode-tooltip" data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                <div className={styles.tooltipContainer}>Primitive Mode: Hides bracketed text. Hides brackets and braces and footnotes pertaining to brackets and braces.</div>
              )} />
            </FormGroup>
          </li>
          <li>
            <FormControl size="small">
              <Select
                sx={{ color: "white" }}
                value={currentFont}
                onChange={(e) => dispatch(setFont(e.target.value))}
              >
                {fonts.map((font, i) => (
                  <MenuItem key={i} value={font}>{font}</MenuItem>
                ))}
              </Select>
              <FormHelperText sx={{ color: "white" }}>Select a font</FormHelperText>
            </FormControl>
          </li>
          <li>
            <FormControl size="small">
              <Select
                sx={{ color: "white" }}
                value={currentTranslation}
                onChange={(e) => dispatch(setTranslation(e.target.value))}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {translations.map((translation, i) => (
                  <MenuItem key={i} className={styles.menuItem} value={translation}>{translation}</MenuItem>
                ))}
              </Select>
              <FormHelperText sx={{ color: "white" }}>Select a Parallel Translation</FormHelperText>
            </FormControl>
          </li>
        </ul>
      </div>

      <Tooltip className={styles.tooltip} id="mode-tooltip" disableStyleInjection={true} />
    </div>
  );
}
