import React from 'react'
import { Footnote } from '../entities/translation.entity';
import styles from "./FootnoteTooltip.module.css";

interface Properties {
  footnote: Footnote;
}

export default function FootnoteTooltip(props: Properties) {
	return (
		<div className={styles.tooltipContainer}>
			<span>{props.footnote.fr + " "}</span>
			<span><b>{props.footnote.fk + " "}</b></span>
			{props.footnote.ft.map((footnoteText, i) => (
				<span key={i}>
					{footnoteText.italic && <i>{footnoteText.text}</i>}
					{footnoteText.superscript && <sup>{footnoteText.text}</sup>}
					{!footnoteText.italic && !footnoteText.superscript && <span>{footnoteText.text}</span>}
				</span>
			))}
		</div>
	)
}
