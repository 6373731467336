import { createSlice } from "@reduxjs/toolkit";

export const primitiveModeSlice = createSlice({
	name: "primitiveMode",
	initialState: {
		value: false,
	},
	reducers: {
		setPrimitiveMode: (state, action) => {
			state.value = action.payload;
		},
	},
});

export const { setPrimitiveMode } = primitiveModeSlice.actions;
export default primitiveModeSlice.reducer;