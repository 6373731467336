import { createSlice } from "@reduxjs/toolkit";

export const translationSlice = createSlice({
	name: "translation",
	initialState: {
		value: "",
	},
	reducers: {
		setTranslation: (state, action) => {
			state.value = action.payload;
		},
	},
});

export const { setTranslation } = translationSlice.actions;
export default translationSlice.reducer;