import React from "react";
import styles from "./Home.module.css"
import BookChapterForm from "../components/BookChapterForm";

export default function Home() {
	return (
		<div className={styles.main}>
			<div className={styles.home}>
				<div className={styles.bookChapterForm}>
					<span className={styles.bookChapterHeader}>The AI Critical New Testament (AICNT)</span>
					<BookChapterForm showChapter={false} />
				</div>
				<span className={styles.arrowDown + " material-symbols-outlined"}>keyboard_arrow_down</span>
			</div>
			<div className={styles.description}>
				<h1 className={styles.header}>Overview</h1>
				<p className={styles.textParagraph}>
					The AI Critical New Testament (AICNT) sets a new standard in the unbiased translation of Scripture.
					God has revealed himself through the Bible—The best-selling book of all time.
					However, mainstream English versions often reflect the inherent biases of translators and editors.
					Such biases are not merely textual; they are amplified through formatting choices and suggestive section headings,
					leading readers down predetermined interpretive paths.
					The opacity in these translations can sometimes render them closer to a commentary on the meaning of the text,
					instead of a neutral rendering of what the ancient text says.
				</p>
				<p className={styles.textParagraph}>
					Faced with the challenge of human bias in translating holy Scripture, the question arises:
					where can one find an impartial rendition? Recent technological advancements offer an answer in the form of Artificial Intelligence.
					Specifically, OpenAI’s GPT-4 Large Language Model (LLM) provides a highly precise and consistent
					tool for translating Biblical Greek when programmed with appropriate guidelines.
					The AICNT thus serves as a reliable resource for those seeking a transparent presentation of what the New Testament manuscripts genuinely convey,
					rather than an interpretation shaped by human beliefs.
				</p>
				<p className={styles.textParagraph}>
					With the appropriate system instructions, parameters, and methodology,
					GPT-4 is capable of rendering a highly accurate and repeatable output when translating biblical Greek. 
					For those who want a clear rendering of what the New Testament manuscripts say, as opposed to what human translators believe it means,
					the AI Critical New Testament is the solution.
				</p>
				<p className={styles.textParagraph}>
					The AICNT further aims to indicate the extent to which the manuscripts have been altered throughout the centuries.
					This is accomplished first by using, as the base text, what scholars have identified as most likely the earliest reading of the text translated.
					Secondly, over 2,000 of the most significant textual variants are noted in the footnotes to disclose significant differences
					between manuscripts and reveal substantive changes and additions to later manuscripts – such changes that found their way into many Bible translations.
				</p>
				<p className={styles.textParagraph}>
					The AICNT translation and footnotes are based on leading critical Greek texts as determined by modern scholarship
					to indicate the most probable readings of the earliest manuscripts.
					Brackets are employed to indicate text included in the translation that the most recent critical editions of the Greek text identify as not likely original.
					The AICNT further utilizes the BDAG lexicon for precise lexical choices in the rendering of words.
				</p>
				<p className={styles.textParagraph}>
					The AICNT was developed with a focus on direct transparency. Rather than coloring the text with interpretative bias and suggestive editorial elements,
					it delivers an English reading of the ancient text in the most unfiltered and impartial manner feasible.
					The translation strives to be a transparent medium through which the original text is conveyed,
					thereby affording readers access to the full spectrum of possible interpretation.
				</p>
				<p className={styles.textParagraph}>
					AI is a game changer for obtaining neutral and unbiased translations of ancient texts.
					The AICNT is part of the larger GPT.Bible project to use the most advanced GPT LLM’s to provide neutral and transparent translations of Biblical texts in modern English.
				</p>
				<h1 className={styles.header}>Editors</h1>
				<p className={styles.textParagraph}>
					Theophilus Josiah, founder of Integrity Syndicate, is a modern-day Christian reformer seeking restoration of the original faith of Christ and his Apostles.
					Josiah is an innovator and analytical thinker with a background in engineering.
					He is the architect of the AICNT and directed all aspects of its methodology and design.
				</p>
				<p className={styles.textParagraph}>
					Dustin R. Smith, Ph.D., is Professor of Theological Studies at Reformed University, where he teaches biblical languages in the undergraduate and graduate programs.
					As a coeditor, Professor Smith’s meticulous examination of the accuracy of the English with respect to the underlying Greek,
					his readability assessment, and his contributions to footnotes were instrumental in ensuring the quality of the AICNT.
				</p>
				<h1 className={styles.header}>About Integrity Syndicate</h1>
				<p className={styles.textParagraph}>
					Integrity Syndicate, founded by Theophilus Josiah in 2020, maintains various websites and publishes content for the restoration and promotion of 1st-century apostolic Christianity.
				</p>
				<p className={styles.textParagraph}>
					The first major publication is the AI Critical New Testament, available at aicnt.org, which is an AI-rendered translation designed
					to avoid the biases of human translators and optimized for transparency, accuracy, and readability.
				</p>
				<p className={styles.textParagraph}>
					The restorationist approach of Integrity Syndicate toward reclaiming authentic Christianity is firmly rooted in the foundational authorities of the New Testament,
					with a particular focus on the essential texts of Luke-Acts and the writings of Paul.
				</p>
				<p className={styles.textParagraph}>
					To stay informed of updates and announcements, sign up for the newsletter below.
					Your support is needed and appreciated for this and future publications! Please consider donating.
				</p>
			</div>
			<div className={styles.footer}>
				<div className={styles.buttonContainer}>
					<a href="https://amzn.to/3slC6Gy" className={styles.footerButton}>Purchase copies</a>
					<a href="https://www.paypal.com/donate/?hosted_button_id=TLXXS6CNYWQZ4&sdkMeta=eyJ1cmwiOiJodHRwczovL3d3dy5wYXlwYWxvYmplY3RzLmNvbS9kb25hdGUvc2RrL2RvbmF0ZS1zZGsuanMiLCJhdHRycyI6eyJkYXRhLXVpZCI6ImFkMGJmMDdiYzRfbWRjNm50ZzZtZGkifX0&targetMeta=eyJ6b2lkVmVyc2lvbiI6IjlfMF81OCIsInRhcmdldCI6IkRPTkFURSIsInNka1ZlcnNpb24iOiIwLjguMCJ9"
						className={styles.footerButton}>
						Contribute Now
					</a>
					<a href="https://dashboard.mailerlite.com/forms/553339/96550735583380682/share" className={styles.footerButton}>Sign up for updates</a>
				</div>
				<div className={styles.copyrightContainer}>
					<img className={styles.logo} src="\images\Integrity_Syndicate1200_wide.png" />
					<div className={styles.privacyPolicyContainer}>
						<p className={styles.copyrightParagraph}>Copyright 2023 Integrity Syndicate, LLC</p>
						<a className={styles.privacyPolicy} href="https://integritysyndicate.com/privacy-policy/">Privacy Policy</a>
					</div>
				</div>
			</div>
		</div>
	)
}
