import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import styles from "./Layout.module.css";

export default function Layout() {
  return (
    <div className={styles.main}>
      <Header className={styles.header} />
      <div className={styles.mainContent}>
        <Outlet />
      </div>
      <Footer className={styles.footer} />
    </div>
  );
}
