import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useRouteLoaderData } from "react-router-dom";
import ReactDOMServer from 'react-dom/server';
import { Tooltip } from "react-tooltip";
import { BookInfo } from "../entities/translation.entity";
import styles from "./BookChapterForm.module.css";

interface Properties {
	showChapter: boolean;
}

export default function BookChapterForm(props: Properties) {
	const params = useParams();
	const navigate = useNavigate();
	const bookInfos = useRouteLoaderData("root") as BookInfo[];

	const [bookInfo, setBookInfo] = useState<BookInfo>();
	const [currentChapterNumber, setCurrentChapterNumber] = useState<number>(1);
	const [isChapterDialogOpen, setChapterDialog] = useState(false);
	const [searchInput, setSearchInput] = useState<string>();

	useEffect(() => {
		if (params.book !== undefined && params.chapter !== undefined) {
			const bookInfo = bookInfos.find(bookInfo => bookInfo.h.toLowerCase() === params.book?.toLowerCase());
			if (bookInfo !== undefined) {
				setBookInfo(bookInfo);
				setCurrentChapterNumber(+params.chapter);
			}
		}
	}, [params]);

	return (
		<>
			<div className={styles.mainContainer}>
				<div className={styles.bookChapterForm}>
					<select required className={styles.bookForm} value={bookInfo?.h} onChange={e => {
						const value = e.target.value
						const bookInfo = bookInfos.find(bookInfo => bookInfo.h === value);
						if (bookInfo !== undefined) {
							navigate(bookInfo.h + "/1");
						} else if (value !== "") {
							navigate(value);
						}
					}}>
						{!props.showChapter && <option disabled selected hidden value={""}>Select book</option>}
						<option value={"preface"}>Preface</option>
						{bookInfos.map((bookInfo, i) => (
							<option value={bookInfo.h} key={i}>
								{bookInfo.h}
							</option>
						))}
					</select>
					{props.showChapter && <div className={styles.chapterForm}>
						{bookInfo?.chapterCount !== undefined && bookInfo.chapterCount > 0 &&
							<button className={styles.chapterButton} onClick={() => setChapterDialog(!isChapterDialogOpen)}>
								{currentChapterNumber}
							</button>
						}
						<table style={{visibility: isChapterDialogOpen ? "visible" : "hidden"}} className={styles.chapterDialog}>
							<tbody>
								{bookInfo !== undefined &&
									chunkArray(Array.from({length: bookInfo.chapterCount}, (_, i) => i + 1), 5).map((chapterNumberChunk, i) => (
										<tr key={i}>
											{chapterNumberChunk.map((chapterNumber, j) => (
												<td className={styles.chapterDialogEntry} key={j} onClick={() => {
													setChapterDialog(false);
													navigate(bookInfo.h + "/" + chapterNumber);
												}}>
													{chapterNumber}
												</td>
											))}
										</tr>
									))}
							</tbody>
						</table>
					</div>}
				</div>
				<form className={styles.searchForm} onSubmit={e => {
					e.preventDefault();
					let resultFound = false;
					if (searchInput !== undefined && searchInput !== "") {
						bookInfos.forEach(bookInfo => {
							Array.from({length: bookInfo.chapterCount}, (_, i) => i + 1).forEach(chapterNumber => {
								const possibleLookups: string[] = [];
								for (let name of Object.values(bookInfo).slice(0, -1)) {
									possibleLookups.push(name.toLowerCase() + " " + chapterNumber);
									possibleLookups.push(name.replaceAll(" ", "").toLowerCase() + " " + chapterNumber);
								}
								if (possibleLookups.includes(searchInput.toLowerCase().trim())) {
									navigate("/" + bookInfo.h + "/" + chapterNumber);
									resultFound = true;
									return;
								}
							});
						});
						if (searchInput.length !== 0 && searchInput.replaceAll(" ", "").length !== 0 && !resultFound) {
							navigate({ pathname: "search", search: "text=" + searchInput });
						}
					}
				}}>
					<input className={styles.searchBar} placeholder="Search" onChange={e => setSearchInput(e.target.value)} data-tooltip-id="mode-tooltip" data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
						<div className={styles.tooltipContainer}>
							<span>Search by chapter & verse or by words.</span>
							<span>Quotation marks (e.g., &quot;hear o israel&quot;) searches for the exact phrase.</span>
							<span>No quotation marks searches for verses containing words typed.</span>
							<span>Example chapter: 1Thessalonians 4</span>
							<span>Example verse: 1Th 4:2 | 1 Thess 4 2-5 | First Thessalonians 4.2-3</span>
						</div>
					)} />
					<button className={styles.searchIcon + " material-symbols-outlined"}>search</button>
				</form>
			</div>

			<Tooltip className={styles.tooltip} id="mode-tooltip" disableStyleInjection={true} />
		</>
	)
}

function chunkArray<T>(arr: T[], size: number): (T[])[] {
  return arr.length > size
    ? [arr.slice(0, size), ...chunkArray(arr.slice(size), size)]
    : [arr];
}
