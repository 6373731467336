import React from "react";
import styles from "./WordComponent.module.css";

interface Properties {
  word: string;
}

export default function WordComponent({ word }: Properties) {
  return (
    <span>
      {" "}<span>{word}</span>
    </span>
  );
}
