import React from "react";
import styles from "./Footer.module.css";

interface Properties {
  className: string;
}

export default function Footer(props: Properties) {
  return (
    <div className={props.className + " " + styles.mainContainer}>
      <div className={styles.paragraphContainer}>
        <p>
          The AI Critical New Testament  (AICNT) features an immense critical apparatus of textual variants and is
          translated using GPT-4 with a methodology to ensure an unbiased and transparent output.
        </p>
        <p>
          The critical apparatus and associated updates to the translation are in the process of being completed. The apparatus and footnotes are currently
          more detailed in some books than others. The finished New Testament books correspond to those published on Amazon as part of
          the <a href="https://amzn.to/3slC6Gy" className={styles.link}>GPT.Bible Series</a> (As an Amazon associate, commissions are earned on qualifying purchases). 
        </p>
        <p>
        <a href="https://dashboard.mailerlite.com/forms/553339/96550735583380682/share" className={styles.link}>Sign up for updates</a> to be notified when additional New Testament books are published, having been thoroughly reviewed with a finished critical apparatus. 
        </p>
        <p>
         Send corrections or suggestions to info@integritysyndicate.com
        </p>
        <p>
         Copyright 2023, Integrity Syndicate
        </p>
        <p>
          <a className={styles.privacyPolicy} href="https://integritysyndicate.com/privacy-policy/">Privacy Policy</a>
        </p>
      </div>
    </div>
  );
}
