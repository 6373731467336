import { createSlice } from "@reduxjs/toolkit";

export const fontSlice = createSlice({
	name: "font",
	initialState: {
		value: "Gentium Plus",
	},
	reducers: {
		setFont: (state, action) => {
			state.value = action.payload;
		},
	},
});

export const { setFont } = fontSlice.actions;
export default fontSlice.reducer;