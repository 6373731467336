import { createSlice } from "@reduxjs/toolkit";

export const readerModeSlice = createSlice({
	name: "readerMode",
	initialState: {
		value: false,
	},
	reducers: {
		setReaderMode: (state, action) => {
			state.value = action.payload;
		},
	},
});

export const { setReaderMode } = readerModeSlice.actions;
export default readerModeSlice.reducer;