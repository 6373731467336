import React from "react";
import styles from "./Error.module.css"

export default function Error() {
  return (
    <div>
      404 - Page not found
    </div>
  );
}
