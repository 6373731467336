import { Book, BookChapterNavigation, BookInfo, Chapter, Preface, SearchResult } from "../entities/translation.entity";

const baseUrl = "/api/translation";

export async function getTranslations(): Promise<string[]> {
	const response = await fetch(`${baseUrl}/translations`);
	const json = await response.json();
	return json.translations;
}

export async function getPreface(): Promise<Preface> {
	const response = await fetch(`${baseUrl}/preface`);
	return response.json();
}

export async function getBookInfos(): Promise<BookInfo[]> {
	const response = await fetch(`${baseUrl}/bookInfos`);
	const json = await response.json();
	return json.bookInfos;
}

export async function getBooks(): Promise<Book[]> {
	const response = await fetch(`${baseUrl}/books`);
	const json = await response.json();
	return json.books;
}

export async function getNextBook(bookName: string): Promise<BookChapterNavigation> {
	const response = await fetch(`${baseUrl}/nextBook?bookName=${bookName}`);
	return response.json();
}

export async function getPreviousBook(bookName: string): Promise<BookChapterNavigation> {
	const response = await fetch(`${baseUrl}/previousBook?bookName=${bookName}`);
	return response.json();
}

export async function getChapter(bookName: string, chapterNumber: string, translation?: string, primitive?: string): Promise<Chapter> {
	const response = await fetch(`
		${baseUrl}/chapters
		?bookName=${bookName}
		&chapterNumber=${chapterNumber}
		${translation !== undefined ? "&translation=" + translation : ""}
		${primitive !== undefined ? "&primitive=" + primitive : ""}
	`);
	return response.json();
}

export async function getNextChapter(bookName: string, chapterNumber: string): Promise<BookChapterNavigation> {
	const response = await fetch(`${baseUrl}/nextChapter?bookName=${bookName}&chapterNumber=${chapterNumber}`);
	return response.json();
}

export async function getPreviousChapter(bookName: string, chapterNumber: string): Promise<BookChapterNavigation> {
	const response = await fetch(`${baseUrl}/previousChapter?bookName=${bookName}&chapterNumber=${chapterNumber}`);
	return response.json();
}

export async function getSearchResults(text: string): Promise<SearchResult[]> {
	const response = await fetch(`${baseUrl}/search?text=${text}`);
	const json = await response.json();
	return json.results;
}
