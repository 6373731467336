import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import copy from "copy-to-clipboard";
import { useStoreSelector } from "../stores/store";
import { SearchResult } from "../entities/translation.entity";
import { getSearchResults } from "../services/translation.service";
import styles from "./SearchResults.module.css";
import Loader from "../components/Loader";

export default function SearchResults() {
  const [params] = useSearchParams();

  const currentFont = useStoreSelector((state) => state.font.value);

  const [results, setResults] = useState<SearchResult[]>([]);
  const [longTouch, setLongTouch] = useState(false);
  const [touchStartX, setTouchStartX] = useState<number>(0);
  const [touchEndX, setTouchEndX] = useState<number>(0);
  const [touchStartY, setTouchStartY] = useState<number>(0);
  const [touchEndY, setTouchEndY] = useState<number>(0);
  const [searchResultLoading, setSearchResultLoading] = useState(true);

  useEffect(() => {
    const fetchSearchResults = async () => {
      try {
        const text = params.get("text");
        if (text !== null) {
          setSearchResultLoading(true);
          const results = await getSearchResults(text);
          setResults(results[0].verses.length > 0 ? results : []);
        }
      } finally {
        setSearchResultLoading(false);
      }
    };
    fetchSearchResults();
  }, [params]);

  if (searchResultLoading) {
    return <Loader />;
  }

  return (
    <div className={styles.searchResultsContainer}>
      <h1 className={styles.header}>Search Results ({results.length})</h1>
      <h5 className={styles.headerHint}>(Right click to copy verse)</h5>
      {results.map((result, i) => (
        <Link to={"/" + result.bookInfo.h.replaceAll(" ", "") + "/" + result.chapter} key={i} className={styles.searchResultsParagraphContainer} onContextMenu={(e) => e.preventDefault()}
          onMouseDown={(e) => {
            if (e.button === 2) {
              copyResult(result);
          }}}
          onTouchStart={e => setTimeout(() => {
            setTouchEndX(e.targetTouches[0].clientX);
            setTouchStartX(e.targetTouches[0].clientX);
            setTouchEndY(e.targetTouches[0].clientY);
            setTouchStartY(e.targetTouches[0].clientY);
            setLongTouch(true)
          }, 1500)}
          onTouchMove={e => {
            setTouchEndX(e.targetTouches[0].clientX);
            setTouchEndY(e.targetTouches[0].clientY);
          }}
          onTouchEnd={() => {
            const distanceX = touchStartX - touchEndX;
            const distanceY = touchStartY - touchEndY;
            if (distanceX === 0 && distanceY === 0 && longTouch) {
              copyResult(result);
            }
            setLongTouch(false);
          }}
        >
          <h4 className={styles.bookChapterHeader}>
            {result.bookInfo.h + " " + result.chapter + ":" + result.verses[0].number +
            (result.verses.flatMap(verse => verse.number).filter((number, index, array) => array.indexOf(number) === index).length > 1 ? "-" + result.verses[result.verses.length - 1].number : "") + " (AICNT)"}
          </h4>
          <p>
            {result.verses.map((verse, j) => (
              <span key={j} style={{fontFamily: currentFont}}>
                {verse.beginning && <sup className={styles.verseNumber}><b>{verse.number}</b></sup>}
                {" " + verse.texts.flatMap(text => text.text).join("")}
              </span>
            ))}
          </p>
        </Link>
      ))}
    </div>
  );
}

function copyResult(result: SearchResult) {
  const verses = result.verses;
  const verseNumbers = verses.flatMap(verse => verse.number).filter((number, index, array) => array.indexOf(number) === index);
  if (verseNumbers.length > 1) {
    let resultText = "";
    verseNumbers.forEach(number => {
      verses.filter(verse => verse.number === number).forEach(verse => {
        resultText = resultText + (verse.beginning ? verse.number + " " : " ");
        verse.texts.forEach(text => {
          resultText = resultText + text.text;
        });
      });
      resultText = resultText + "\n";
    });
    copy(result.bookInfo.h + " " + result.chapter + ":" + verses[0].number + "-" + verses[verses.length - 1].number + " (AICNT)\n" + resultText);
  } else {
    copy(result.bookInfo.h + " " + result.chapter + ":" + verses[0].number + " (AICNT)\n" + verses.flatMap(verse => verse.texts).map(text => text.text).join(""));
  }
}
