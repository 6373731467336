import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./stores/store";
import { getBookInfos, getPreface } from "./services/translation.service";
import RootLayout from "./layouts/RootLayout";
import Layout from "./layouts/Layout";
import Home from "./pages/Home";
import PrefacePage from "./pages/PrefacePage";
import SearchResults from "./pages/SearchResults";
import BookPage from "./pages/BookPage";
import Error from "./components/Error";
import "./index.css";

const router = createBrowserRouter([
  {
    element: <RootLayout />,
    loader: async () => await getBookInfos(),
    id: "root",
    children: [
      {
        element: <Home />,
        path: "",
      },
      {
        element: <Layout />,
        children: [
          {
            element: <PrefacePage />,
            path: "preface",
            loader: async () => await getPreface(),
            id: "preface",
          },
          {
            element: <BookPage />,
            path: ":book/:chapter",
          },
          {
            element: <SearchResults />,
            path: "search",
          },
          {
            element: <Error />,
            path: "*",
          },
        ],
      },
    ]
  }
]);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);
