import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import readerModeReducer from "./readerModeSlice";
import primitiveModeReducer from "./primitiveModeSlice";
import fontReducer from "./fontSlice";
import translationReducer from "./translationSlice";

export const store = configureStore({
	reducer: {
		readerMode: readerModeReducer,
		primitiveMode: primitiveModeReducer,
		font: fontReducer,
		translation: translationReducer,
	},
});

type RootState = ReturnType<typeof store.getState>;
type StoreDispatch = typeof store.dispatch;

export const useStoreDispatch: () => StoreDispatch = useDispatch;
export const useStoreSelector: TypedUseSelectorHook<RootState> = useSelector;