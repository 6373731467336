import React from 'react'
import ReactDOMServer from 'react-dom/server';
import { useStoreSelector } from '../stores/store';
import { Text } from '../entities/translation.entity';
import FootnoteTooltip from "../components/FootnoteTooltip";
import WordComponent from './WordComponent';
import styles from "./TextComponent.module.css";

interface Properties {
  text: Text;
}

export default function TextComponent({ text }: Properties) {
	const isReaderMode = useStoreSelector((state) => state.readerMode.value);

	return (
		<span>
			{text.italic && <i>{" " + text.text + " "}</i>}
			{text.superscript && <sup>{text.text}</sup>}
			{text.strong !== undefined && <WordComponent word={text.text} />}
			{!text.italic && !text.superscript && text.strong === undefined && text.text}
			{text.footnotes.length !== 0 && !isReaderMode && text.footnotes.map((footnote, i) => (
				<sup key={i} className={styles.footnote} data-tooltip-id="footnote-tooltip"
				data-tooltip-html={ReactDOMServer.renderToStaticMarkup(<FootnoteTooltip footnote={footnote} />)}>
					{footnote.index}
				</sup>
			))}
		</span>
	)
}
