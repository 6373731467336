import React from "react";
import { useRouteLoaderData } from "react-router-dom";
import ReactDOMServer from 'react-dom/server';
import { Tooltip } from "react-tooltip";
import { useStoreSelector } from "../stores/store";
import FootnoteTooltip from "../components/FootnoteTooltip";
import { Preface } from "../entities/translation.entity";
import styles from "./PrefacePage.module.css";

export default function PrefacePage() {
  const preface = useRouteLoaderData("preface") as Preface;
	const currentFont = useStoreSelector((state) => state.font.value);

  return (
    <>
      <div className={styles.mainContainer}>
        {preface.lines.map((line, i) => (
          <div className={styles.paragraphContainer} key={i}>
            {line.type === "imt1" && <h2 className={styles.mainHeader}>{line.texts.map(text => text.text).join("")}</h2>}
            {(line.type === "imt2" || line.type === "imt3") && <h3 className={styles.sideHeader}>{line.texts.map(text => text.text).join("")}</h3>}
            {line.type === "ip" &&
              <p className={styles.textParagraph} style={{fontFamily: currentFont}}>
                {line.texts.map((text, k) => (
                  text.footnotes.length !== 0
                  ? <span key={k}>
                      {text.italic ? <i>{text.text}</i> : text.superscript ? <sup>{text.text}</sup> : text.text}
                      {text.footnotes.map((footnote, l) => (
                        <sup key={l} className={styles.footnote} data-tooltip-id="footnote-tooltip"
                          data-tooltip-html={ReactDOMServer.renderToStaticMarkup(<FootnoteTooltip footnote={footnote} />)}
                        >
                          {footnote.index + " "}
                        </sup>
                      ))}
                    </span>
                  : <span key={k}>
                      {text.italic ? <i>{text.text}</i> : text.superscript ? <sup>{text.text}</sup> : text.bold ? <b>{text.text}</b> : text.text}
                    </span>
                ))}
              </p>
            }
          </div>
        ))}
        <div className={styles.footnoteContainer}>
          {preface.lines.map(line => line.texts.map(text => text.footnotes.map((footnote, i) => (
            <p key={i}>
              <b>{footnote.index + ": "}</b>
              <span>{footnote.fr + " "}</span>
              <span><b>{footnote.fk + " "}</b></span>
              {footnote.ft.map((footnoteText, j) => (
                <span key={j}>
                  {footnoteText.italic ? <i>{footnoteText.text}</i> : footnoteText.superscript ? <sup>{footnoteText.text}</sup> : footnoteText.text}
                </span>
              ))}
            </p>
          ))))}
        </div>
      </div>

      <Tooltip className={styles.tooltip} id="footnote-tooltip" disableStyleInjection={true} clickable />
    </>
  );
}
