import React from 'react'
import { useStoreSelector } from '../stores/store';
import { Verse } from '../entities/translation.entity';
import TextComponent from './TextComponent';
import styles from "./VerseComponent.module.css";

interface Properties {
  verse: Verse;
	isParallel: boolean;
}

export default function VerseComponent({ verse, isParallel }: Properties) {
	const isReaderMode = useStoreSelector((state) => state.readerMode.value);
	const currentFont = useStoreSelector((state) => state.font.value);

	return (
		<span>
			{verse.type === "dt"
				? !isParallel && <h4 className={styles.descriptiveTitle}>
						{verse.texts.map((text, j) => 
							text.italic ? (<i key={j}>{text.text}</i>) : text.text
						)}
					</h4>
				: <span style={{fontFamily: currentFont}}>
						{verse.paragraph && (isParallel ? " " : <p />)}
						{verse.beginning && !isReaderMode && <sup className={styles.verseNumber}><b>{" " + verse.number + " "}</b></sup>}
						{verse.texts.map((text, k) => <TextComponent key={k} text={text} />)}
					</span>
			}
		</span>
	)
}
